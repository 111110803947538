@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

html,
body {
  font-family: "Poppins", sans-serif;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.slick-prev {
  left: -30px !important;
  width: 100px !important;
  height: 100px !important;
  z-index: 1;
}
.slick-next {
  right: -30px !important;
  width: 100px !important;
  height: 100px !important;
}

.slick-dots {
  top: 100%;
}
ul.slick-dots li {
  margin-left: 5rem;
}
ul.slick-dots li:nth-of-type(1) {
  margin-left: -1rem;
}
.bottom-pics {
  width: 100px;
  height: 70px;
}

@media screen and (max-width: 600px) {
  .bottom-pics {
    display: none;
  }
}
